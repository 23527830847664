<template>
  <div class="payment-detail">
    <van-config-provider>
      <van-nav-bar :title="$t('title.rechargeDetail')" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
    </van-config-provider>
    <div class="detail-wrap">
      <div class="detail-header-bg"></div>
      <div class="detail-header">
        <span>{{paymentData.sn}}</span>
        <span>{{paymentData.createTimeStr}}</span>
      </div>
      <div class="detail-content">
        <div class="detail-coin">
          <span class="detail-coin-amount">{{paymentData.amountStr}}</span>
        </div>
        <div class="detail-status green">
          {{paymentData.statusStr}}
        </div>
        <van-config-provider :theme-vars="cellThemeVars">
          <van-cell center :title="$t('field.bankCardNumber')" :label="paymentData.bankcardNo">
            <template #right-icon>
              <van-icon class="copy-icon" name="/images/copy.png" @click="copy(paymentData.bankcardNo)" />
            </template>
          </van-cell>
          <van-cell center :title="$t('field.bankName')" :label="paymentData.bankName">
            <template #right-icon>
              <van-icon class="copy-icon" name="/images/copy.png" @click="copy(paymentData.bankName)" />
            </template>
          </van-cell>
          <van-cell center :title="$t('field.payee')" :label="paymentData.bankcardOwner">
            <template #right-icon>
              <van-icon class="copy-icon" name="/images/copy.png" @click="copy(paymentData.bankcardOwner)" />
            </template>
          </van-cell>
        </van-config-provider>
        <div class="btn-bar" v-if="paymentData.status === 2 || paymentData.status === 6">
          <a class="bar-btn" @click="showUpload = true">{{$t('button.uploadCertificate')}}</a>
          <a class="bar-btn" @click="showCancel = true">{{$t('common.cancel')}}</a>
        </div>
        <van-popup v-model:show="showUpload" :close-on-click-overlay="false" position="bottom">
          <div class="popup-title">
            {{$t('button.uploadCertificate')}}
            <span @click="showUpload = false">{{$t('common.cancel')}}</span>
          </div>
          <div class="popup-content">
            <van-uploader v-model="fileList" max-count="1" :after-read="uploadImage" />
          </div>
          <div class="pop-button-wrap">
            <van-button
              size="large"
              type="primary"
              block
              :disabled="!fileList[0] || !fileList[0].url"
              @click="handleFinishPaymentOrder"
            >{{$t('button.confirm')}}</van-button>
          </div>
        </van-popup>
        <van-popup v-model:show="showCancel" :close-on-click-overlay="false" position="bottom">
          <div class="popup-title">
            {{$t('field.cancelMsg')}}
            <span @click="showCancel = false">{{$t('button.close')}}</span>
          </div>
          <div class="popup-content">
            <div class="form-wrap">
              <div class="field-wrap">
                <van-field
                  v-model="msg"
                  class="form-field"
                  size="large"
                  :placeholder="$t('placeholder.default')"
                />
              </div>
            </div>
          </div>
          <div class="pop-button-wrap">
            <van-button
              size="large"
              type="primary"
              block
              :disabled="!msg"
              @click="handleCancelPaymentOrder"
            >{{$t('button.submit')}}</van-button>
          </div>
        </van-popup>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Toast } from 'vant';
import useClipboard from 'vue-clipboard3';
import i18n from '@/assets/lang/index';
import { uploadImg, finishPaymentOrder, cancelPaymentOrder, reUploadPaymentOrder } from "@/api";
import axios from "@/api/http";

export default {
  setup() {
    const { toClipboard } = useClipboard();
    const copy = async (text) => {
      try {
        await toClipboard(text);
        Toast(i18n.global.t('notify.copySuccess'));
      } catch (e) {
        console.error(e)
      }
    }
    const cellThemeVars = {
      cellBorderColor: 'transparent',
    };

    const fileList = ref([]);
      
    return { copy, cellThemeVars, fileList };
  },
  data() {
    return {
      paymentData: JSON.parse(localStorage.getItem('paymentData')),
      showUpload: false,
      showCancel: false,
      msg: '',
    };
  },
  
  mounted() {
  },

  methods: {
    handleFinishPaymentOrder() {
      const doPost = this.paymentData.status === 6 ? reUploadPaymentOrder : finishPaymentOrder;
      doPost({
        sn: this.paymentData.sn,
        imgUrl: this.fileList[0].url,
      }).then(res => {
        localStorage.setItem('paymentData', JSON.stringify(res.data));
        this.paymentData = res.data;
        this.showUpload = false;
      });
    },
    handleCancelPaymentOrder() {
      cancelPaymentOrder({
        sn: this.paymentData.sn,
        msg: this.msg,
      }).then(res => {
        this.goBack();
      });
    },
    uploadImage(obj){
      this.$nextTick(() => {
        // 此时可以自行将文件上传至服务器
        console.log(obj.file);
        const formData = new FormData();
        formData.append("file", obj.file);
        uploadImg(formData).then(res => {
          this.fileList = [{ url: res.data }];
        });
      })
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.payment-detail {
  .detail-wrap {
    margin: 16px 22px 10px;
    background: #fff;
    .detail-header-bg {
      height: 20px;
      margin-left: -10px;
      margin-right: -10px;
      border-radius: 10px;
      background-color: var(--van-primary-color);
    }
    .detail-header {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 14px 12px;
      margin-top: -10px;
      line-height: 17px;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      background: #fff;
    }
    .detail-content {
      font-size: 14px;
      color: #6F6F93;
      font-weight: 400;
      .detail-coin {
        position: relative;
        line-height: 27px;
        text-align: center;
        margin-bottom: 10px;
        &-amount {
          display: inline-block;
          font-size: 22px;
          margin-right: 2px;
          color: var(--van-primary-color);
          font-weight: 600;
          vertical-align: middle;
        }
        &-type {
          display: inline-block;
          font-size: 16px;
          margin-right: 10px;
          vertical-align: middle;
        }
      }
      .detail-status {
        text-align: center;
        font-size: 14px;
        margin-right: 2px;
        color: var(--van-primary-color);
        &.green { color: #1BA27A; }
        &.red { color: #FF4060; }
        &.warn { color: #FF8F00; }
      }
    }
    .detail-coin-address { vertical-align: middle; }
    .copy-icon {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      vertical-align: middle;
    }
    .btn-bar {
      display: flex;
      align-items: center;
      height: 44px;
      background-color: var(--van-primary-color);
      border-radius: 0 0 12px 12px;
      .bar-btn {
        flex: 1;
        color: #fff;
        text-align: center;
      }
    } 
  }
  .popup-title {
    margin: 0 15px;
    line-height: 60px;
    font-size: 18px;
    color: #000000;
    font-weight: 600;
    border-bottom: 1px dashed #efefef;
    span {
      display: block;
      float: right;
      font-size: 14px;
      color: #6F6F93;
      text-align: left;
    }
  }
  .popup-content {
    padding: 20px 0 0;
    text-align: center;
    .form-title {
      text-align: left;
    }
  }
  .pop-button-wrap {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
  }
  .form-wrap {
    padding: 0 12px 16px;
    background: #fff;
    border-radius: 6px;
    overflow: hidden;
    .form-title {
      margin: 16px 0;
      font-size: 14px;
      color: #28293D;
      font-weight: 400;
    }
    .field-wrap {
      position: relative;
      .suffix-wrap {
        position: absolute;
        top: 0;
        right: 12px;
        width: 110px;
        line-height: 46px;
        text-align: right;
        font-weight: 400;
        color: #6F6F93;
        span {
          font-size: 16px;
          vertical-align: middle;
        }
        .triangle {
          width: 8px;
          margin-left: 12px; 
          vertical-align: middle;
        }
      }
    }
    .form-field {
      font-size: 16px;
      font-weight: 500;
      color: #000;
      background: #F9FAFB;
      border-radius: 4px;
      &.with-suffix {
        padding-right: 110px;
      }
    }
    .field-extra {
      padding: 8px 0;
      font-size: 10px;
      color: #6F6F93;
      line-height: 12px;
      font-weight: 400;
      .highlight {
        color: var(--van-primary-color);
      }
    }
  }
}
</style>
